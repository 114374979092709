import store from '@/state/store.js';
import MainUserComponent from './mainsUsersComponent';
import appConfig from '@/app.config';
import userSegments from './userSegments.vue';
export default {
  page: {
    title: 'SME Users',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  name: 'tagged-users',
  components: {
    MainUserComponent,
    userSegments
  },
  data() {
    return {
      parent: {
        name: 'sme',
        storeDataName: 'smeUsers',
        dispatchName: 'admin/users/getSmeUsers',
        searchDispatchName: 'admin/users/searchSmeUsers'
      }
    };
  },
  methods: {},
  computed: {},
  mounted() {
    // store.dispatch('admin/users/getSmeUsers').then((data) => {

    // })
  }
};