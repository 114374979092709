var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {}, [_c('div', {
    staticClass: "__side_nav"
  }, [_c('button', {
    staticClass: "btn",
    class: [_vm.$route.path == '/admin/users/individual' ? '__side_item_active' : ''],
    on: {
      "click": function ($event) {
        _vm.$router.replace('/admin/users/individual').catch(err => {});
      }
    }
  }, [_vm._v(" Individual ")]), _c('button', {
    staticClass: "btn",
    class: [_vm.$route.path == '/admin/users/sme' ? '__side_item_active' : ''],
    on: {
      "click": function ($event) {
        _vm.$router.replace('/admin/users/sme').catch(err => {});
      }
    }
  }, [_vm._v(" SME ")]), _c('button', {
    staticClass: "btn",
    class: [_vm.$route.path == '/admin/users/corporate' ? '__side_item_active' : ''],
    on: {
      "click": function ($event) {
        _vm.$router.replace('/admin/users/corporate').catch(err => {});
      }
    }
  }, [_vm._v(" Corporate ")])]), _c('MainUserComponent', {
    attrs: {
      "parent": _vm.parent
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };